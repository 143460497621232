import BackButton from '@/components/BackButton.vue';
import { SPACE_ROUTES } from '@/shared/const';
import { defineComponent, computed, onMounted, ref, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
    name: 'SpaceOnboardingPage',
    components: {
        BackButton
    },
    setup(_, context) {
        const { root } = context;
        const ready = ref({});
        const toast = useToast();
        const spaceWizard = computed(() => {
            return globalThis.$store.getters['$_vendor/spaceWizard'];
        });
        const venueId = ref(null);
        onMounted(() => {
            const venueIdFromStorage = globalThis.$store.getters['$_vendor/venueId'] ||
                localStorage.getItem('createdVenueId');
            venueId.value = venueIdFromStorage ? parseInt(venueIdFromStorage) : null;
            if (!venueId.value) {
                toast.error(root.$i18n.t('common.errors.something_wrong'));
                return globalThis.$router.replace('/vendor/onboarding/venue/details');
            }
            // TODO: move this logic to the store?
            ready.value = {
                details: !!localStorage.getItem('space_details'),
                configurations: !!localStorage.getItem('space_configurations'),
                bookingRules: !!localStorage.getItem('space_booking_rules'),
                facilities: !!localStorage.getItem('space_facilities'),
                pricing: !!localStorage.getItem('space_pricing'),
                features: !!localStorage.getItem('space_features')
            };
        });
        watch(() => spaceWizard.value, value => {
            if (value === 'CONFIGURATIONS') {
                ready.value = {
                    details: true,
                    configurations: false,
                    bookingRules: false,
                    facilities: false,
                    pricing: false,
                    features: false
                };
            }
            else if (value === 'BOOKING_RULES') {
                ready.value = {
                    details: true,
                    configurations: true,
                    bookingRules: false,
                    facilities: false,
                    pricing: false,
                    features: false
                };
            }
            else if (value === 'FACILITIES') {
                ready.value = {
                    details: true,
                    configurations: true,
                    bookingRules: true,
                    facilities: false,
                    pricing: false,
                    features: false
                };
            }
            else if (value === 'PRICING') {
                ready.value = {
                    details: true,
                    configurations: true,
                    bookingRules: true,
                    facilities: true,
                    pricing: false,
                    features: false
                };
            }
            else if (value === 'FEATURES') {
                ready.value = {
                    details: true,
                    configurations: true,
                    bookingRules: true,
                    facilities: true,
                    pricing: true,
                    features: false
                };
            }
        }, { immediate: true });
        return {
            ready,
            SPACE_ROUTES
        };
    }
});
